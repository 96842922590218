<template>
    <Layout>
        <div class="w-100">
            <div class="row justify-content-center">
                <div class="col-10 col-sm-7 col-md-5 col-lg-8 col-xl-7 col-xxl-6 login-wrapper py-5">
                    <div class="mx-auto login-w-limit">
                        <div class="logo-wrapper text-center mb-2">
                            <img src="@/assets/img/bau-logo-for-light.svg" height="35" />
                        </div>
                        <h4 class="text-center by-3 mb-5">
                            {{ $t('payment_status') }}
                        </h4>
                        <h5 class="text-center by-3 mb-5">
                            {{ text }}
                        </h5>
                        <h5 class="text-center by-3 mb-5">
                            {{ $t('you_will_directed') }} {{ second }}
                        </h5>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
<script>
import Base from "@/plugins/Base";

export default {
    name: "UnihallLogin",
    components: {
        Layout: () => import("../layout/Layout")
    },
    data() {
        return {
            url: new URLSearchParams(window.location.search),
            second: 5,
            internalNum: -1
        }
    },
    computed: {
        success() {
            return this.url.get('success') == "1"
        },
        message() {
            return this.url.get('message')
        },
        text() {
            if (this.message === "SUCCESS") {
                return this.$t('unihall_approvet_payment')
            } else if (this.message === "LIMIT_ERROR") {
                return this.$t('insufficient_limit')
            } else if (this.message === "3D_NOT_VERIFIED") {
                return this.$t('not_verified')
            }
            return ""
        }
    },
    methods: {
        closeFunc() {
            if (this.second <= 0) {
                if (this.internalNum > 1) {
                    clearInterval(this.internalNum)
                    window.close()
                }
            } else {
                this.second--
            }

        }
    },
    mounted() {
        const params = new URLSearchParams(window.location.search)
        this.url = params

        setTimeout(() => {
            Base.LocalStorage.set('payment_status', {
                success: params.get('success'),
                message: params.get('message'),
            })
        }, 500);

        this.internalNum = setInterval(this.closeFunc, 1000);
    }
}
</script>
<style lang="">
    
</style>